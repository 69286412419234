<template>
  <ion-page>
    <Header title="Perfil" />

    <ion-content :fullscreen="true">
      <div class="banner" :style="getBackgroundColor(theme)">
        <img src="@/img/chuteiras.svg" />
        <img src="@/img/perfil.svg" />
      </div>

      <div>
        <Avatar
          class="avatar"
          width="90"
          height="90"
          :imageUrl="imagePlayer"
          eventOn
          isProfile
          @onClick="$refs.file.click()"
        />
        <input
          style="display: none;"
          type="file"
          ref="file"
          accept="image/*"
          @change="uploadImage()"
        />
      </div>
      <div
        style="display: flex; justify-content: space-between; width: 90%; margin: auto;"
      >
        <ion-card
          style="width: 47%; background-color: #f3f3f3; margin: 0;"
          @click="setOpenModalTrophies()"
        >
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
          >
            <ion-card-title
              >Troféus
              <span :style="amountTrophies > 0 ? 'color: #2dd36f;' : ''"
                >({{ amountTrophies }})</span
              ></ion-card-title
            >
          </ion-card-header>
          <ion-card-content
            style="display: flex; justify-content: center; align-items: center;"
          >
            <div style="display: flex;">
              .....
              <ion-icon
                style="font-size: 30px; "
                :icon="trophySharp"
                color="warning"
              />
              <ion-icon
                style="font-size: 30px;"
                :icon="trophySharp"
                color="warning"
              />
              .....
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card
          style="width: 47%; background-color: #f3f3f3; margin: 0;"
          @click="setOpenModalAchievements()"
        >
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
          >
            <ion-card-title>Conquistas</ion-card-title>
          </ion-card-header>
          <ion-card-content
            style="display: flex; justify-content: center; align-items: center;"
          >
            <div style="display: flex;">
              .....
              <ion-icon
                style="font-size: 30px; "
                :icon="starSharp"
                color="warning"
              />
              <ion-icon
                style="font-size: 30px;"
                :icon="medalSharp"
                color="warning"
              />
              .....
            </div>
          </ion-card-content>
        </ion-card>
      </div>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="person" />
            <ion-card-title>Nome completo</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('nameEditor')"
          >
            <ion-icon
              v-if="nameEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-input
            v-if="nameEditor"
            placeholder="Digite ..."
            class="ion-no-padding"
            :value="name"
            @ionChange="name = $event.target.value"
          ></ion-input>
          <h1 v-else :style="name ? '' : 'color: #eb445a'">
            {{ name ? name : "Por favor infome seu nome" }}
          </h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
        >
          <div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="at" />

            <ion-card-title>Apelido</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('nicknameEditor')"
          >
            <ion-icon
              v-if="nicknameEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-input
            v-if="nicknameEditor"
            placeholder="Digite ..."
            class="ion-no-padding"
            :value="nickname"
            @ionChange="nickname = $event.target.value"
          ></ion-input>
          <h1 v-else :style="nickname ? '' : 'color: #eb445a'">
            {{ nickname ? nickname : "Por favor infome seu Apelido" }}
          </h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="documentText" />
            <ion-card-title>Descrição</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('descriptionEditor')"
          >
            <ion-icon
              v-if="descriptionEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-textarea
            v-if="descriptionEditor"
            class="ion-no-padding"
            autoGrow
            placeholder="Digite uma descrição..."
            :value="description"
            @ionChange="description = $event.target.value"
          ></ion-textarea>
          <h1 v-else style="height: initial;">
            {{ description ? description : "Não informada" }}
          </h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="happy" />
            <ion-card-title>Idade</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('ageEditor')"
          >
            <ion-icon
              v-if="ageEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <p v-if="ageEditor" style="margin-bottom: 2px;">
            Selecionte a data de nascimento
          </p>
          <ion-datetime
            v-if="ageEditor"
            class="ion-no-padding"
            cancelText="CANCELAR"
            doneText="SALVAR"
            display-format="DD/MM/YYYY"
            :value="age"
            @ionChange="age = $event.target.value"
          ></ion-datetime>
          <h1 v-else :style="ageC ? '' : 'color: #eb445a'">
            {{ ageC ? ageC : "Por favor infome sua idade" }}
          </h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="code" />
            <ion-card-title>Melhor pé</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('bestFootEditor')"
          >
            <ion-icon
              v-if="bestFootEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-select
            v-if="bestFootEditor"
            class="ion-no-padding"
            :value="bestFoot"
            @ionChange="bestFoot = $event.target.value"
          >
            <ion-select-option
              v-for="value in feet"
              :key="value"
              :value="value"
              >{{ value }}</ion-select-option
            >
          </ion-select>
          <h1 v-else>{{ bestFoot ? bestFoot : "Não informado" }}</h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="body" />
            <ion-card-title>Posição</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('positionEditor')"
          >
            <ion-icon
              v-if="positionEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-select
            v-if="positionEditor"
            class="ion-no-padding"
            :value="position"
            @ionChange="position = $event.target.value"
          >
            <ion-select-option
              v-for="value in positions"
              :key="value"
              :value="value"
              >{{ value }}</ion-select-option
            >
          </ion-select>
          <h1 v-else>{{ position ? position : "Não informada" }}</h1>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          class="ion-justify-content-between ion-align-items-center"
          ><div
            style="display: flex;"
            class="ion-justify-content-start ion-align-items-center"
          >
            <ion-icon :color="theme" :icon="heart" />
            <ion-card-title>Time que torce</ion-card-title>
          </div>
          <ion-fab-button
            fill="clear"
            class="ion-no-padding"
            style=" margin-right: -7px;"
            @click="edit('cheeringTeamEditor')"
          >
            <ion-icon
              v-if="cheeringTeamEditor"
              :color="theme"
              :icon="save"
              style="margin-right: 0;"
            />
            <ion-icon
              v-else
              :color="theme"
              :icon="create"
              style="margin-right: 0;"
            />
          </ion-fab-button>
        </ion-card-header>

        <ion-card-content>
          <ion-select
            v-if="cheeringTeamEditor"
            class="ion-no-padding"
            :value="cheeringTeam"
            @ionChange="cheeringTeam = $event.target.value"
          >
            <ion-select-option
              v-for="value in teams"
              :key="value"
              :value="value"
              >{{ value ? value : "Não informar" }}</ion-select-option
            >
          </ion-select>
          <h1 v-else>
            {{ cheeringTeam ? cheeringTeam : "Não informado" }}
          </h1></ion-card-content
        >
      </ion-card>
      <ion-toast
        :is-open="isOpenRef"
        :color="
          responseMessage == 'Alterado com sucesso' ? 'success' : 'danger'
        "
        :message="responseMessage"
        :duration="2000"
        @onDidDismiss="setOpen(false)"
      >
      </ion-toast>

      <div
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 90%; margin: auto; margin-bottom: 15px; "
      >
        <ion-label style="font-size: 12px; color: #666666;"
          >Modo escuro</ion-label
        >
        <ion-toggle
          id="themeToggle"
          :checked="checked"
          @ionChange="changeTheme($event)"
        ></ion-toggle>
      </div>

      <div
        style="display: flex; justify-content: center; width: 90%; margin: auto; margin-bottom: 15px;"
      >
        <ion-button :color="theme" @click="exit()">Sair</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import { useRouter } from "vue-router";
import { calculateAge } from "@/helpers/Functions.js";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { StatusBar } from "@ionic-native/status-bar";

export default {
  name: "Profile",
  mixins: [requestManager, computedManager],
  components: {
    IonContent,
    IonPage,
    IonToast,
    Header,
    Avatar,
  },
  data() {
    return {
      checked: false,
      router: useRouter(),
      isOpenRef: false,
      name: "",
      nickname: "",
      description: "",
      age: "",
      bestFoot: "",
      position: "",
      cheeringTeam: "",
      response: "",
      file: "",
      feet: ["Direito", "Esquerdo"],
      positions: ["Atacante", "Meia", "Zagueiro"],
      teams: [
        "",
        "São Paulo",
        "Santos",
        "Palmeiras",
        "Flamengo",
        "Corinthians",
      ],
      /* Datas de edições */
      nameEditor: false,
      nicknameEditor: false,
      descriptionEditor: false,
      ageEditor: false,
      bestFootEditor: false,
      positionEditor: false,
      cheeringTeamEditor: false,
    };
  },
  computed: {
    amountTrophies() {
      return this.$store.state.base.amountTrophies;
    },
    ageC: {
      get() {
        return calculateAge(...this.age.split("-"));
      },
      set(pValue) {
        this.age = pValue;
      },
    },
  },
  async mounted() {
    if (!this.playerInformation || !this.playerInformation.cdId) {
      const playerInformation = localStorage.getItem("playerInformation");
      this.$store.commit(
        "profile/setPlayerInformation",
        JSON.parse(playerInformation)
      );
    }

    this.name = this.playerInformation.nome;
    this.nickname = this.playerInformation.apelido;
    this.description = this.playerInformation.descricao;
    this.$store.commit(
      "profile/setPlayerImage",
      this.playerInformation.imagemUrl
    );
    this.age = this.playerInformation.dataNascimento;
    this.bestFoot = this.playerInformation.melhorPe;
    this.position = this.playerInformation.posicao;
    this.cheeringTeam = this.playerInformation.timeQueTorce;

    this.checked = !!localStorage.getItem("theme");
  },
  methods: {
    setOpenModalTrophies() {
      this.$store.commit("base/setAllMatchesTrophies", []);
      this.$store.commit("base/addPropsModal", {
        name: "ModalTrophies",
        prop: this.playerInformation.cdId,
      });

      this.$store.commit("base/addShowModal", "ModalTrophies");
    },
    setOpenModalAchievements() {
      this.$store.commit("base/addShowModal", "ModalAchievements");
    },
    changeTheme(ev) {
      document.body.classList.toggle("dark", ev.detail.checked);
      if (ev.detail.checked) {
        StatusBar.backgroundColorByHexString("#000000");
        StatusBar.styleLightContent();
        localStorage.setItem("theme", JSON.stringify(ev.detail.checked));
      } else {
        StatusBar.backgroundColorByHexString("#f5f7f9");
        StatusBar.styleDefault();
        localStorage.removeItem("theme");
      }
    },
    edit(pDataName) {
      this[pDataName] = !this[pDataName];
      if (!this[pDataName]) this.saveProfile();
    },
    async saveProfile() {
      if (this.name && this.nickname) {
        this.loading = true;

        const response = await this.genericRequest("profile/saveProfile", {
          cdId: this.playerInformation.cdId,
          nome: this.name,
          apelido: this.nickname,
          descricao: this.description,
          dataNascimento: this.age.split("T")[0],
          melhorPe: this.bestFoot,
          posicao: this.position,
          timeQueTorce: this.cheeringTeam,
        });
        this.responseMessage = response;

        if (response == "Alterado com sucesso") {
          const playerInformation = JSON.parse(
            localStorage.getItem("playerInformation")
          );
          playerInformation.nome = this.name;
          playerInformation.apelido = this.nickname;
          playerInformation.descricao = this.description;
          playerInformation.dataNascimento = this.age.split("T")[0];
          playerInformation.melhorPe = this.bestFoot;
          playerInformation.posicao = this.position;
          playerInformation.timeQueTorce = this.cheeringTeam;
          localStorage.setItem(
            "playerInformation",
            JSON.stringify(playerInformation)
          );
        } else {
          const playerInformation = JSON.parse(
            localStorage.getItem("playerInformation")
          );
          this.nickname = playerInformation.apelido;
        }
        this.setOpen(true);
        this.loading = false;
        this.nameEditor = false;
        this.nicknameEditor = false;
        this.descriptionEditor = false;
        this.ageEditor = false;
        this.bestFootEditor = false;
        this.positionEditor = false;
        this.cheeringTeamEditor = false;
      }
    },
    exit() {
      localStorage.removeItem("playerInformation");
      this.$store.dispatch("base/resetStates");
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.router.replace("/Login");
      }, 1000);
    },
    getBackgroundColor(pClassName) {
      if (pClassName == "primary") {
        return "background-color: #3880e6;";
      } else if (pClassName == "secondary") {
        return "background-color: #3dc2ff;";
      } else if (pClassName == "tertiary") {
        return "background-color: #5260ff;";
      } else if (pClassName == "success") {
        return "background-color: #2dd36f;";
      } else if (pClassName == "warning") {
        return "background-color: #ffc409;";
      } else if (pClassName == "danger") {
        return "background-color: #eb445a;";
      }
    },
    setOpen(pState) {
      this.isOpenRef = pState;
    },
    async uploadImage() {
      const file = this.$refs.file.files[0];

      if (file) {
        this.loading = true;
        const reader = new FileReader();
        const self = this;
        reader.onload = async (e) => {
          const img = document.createElement("img");
          img.src = e.target.result;

          img.onload = async function() {
            let canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            const MAX_WIDTH = 100;
            const MAX_HEIGHT = 100;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const imageUrl = canvas.toDataURL("image/png");

            const response = await self.genericRequest("profile/uploadImage", {
              file: imageUrl,
              cdId: self.playerInformation.cdId,
            });

            const newImage = response.uploadPath;

            self.$store.commit("profile/setPlayerImage", newImage);

            const playerInformation = JSON.parse(
              localStorage.getItem("playerInformation")
            );

            playerInformation.imagemUrl = newImage;
            localStorage.setItem(
              "playerInformation",
              JSON.stringify(playerInformation)
            );
            self.loading = false;
          };
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
  height: 24px;
  padding-top: 2px;
  min-height: 24px;
}

ion-icon {
  font-size: 18px;
  margin-right: 10px;
}

.avatar {
  margin: auto;
  margin-top: -45px;
  width: 90%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 50%;
  border: 5px solid #ffffff;
}

ion-input {
  font-size: 16px;
}

ion-card-content {
  padding: 10px;
}
ion-card-content h1 {
  font-size: 16px;
  color: #959595;
}

ion-fab-button {
  width: 25px;
  height: 25px;
  --background: transparent;
}

.banner {
  display: flex;
  height: 80px;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.banner img {
  height: 100px;
  margin-right: 20px;
  margin-left: 20px;
  opacity: 0.3;
  width: 100px;
  margin-top: -8px;
}
</style>
